const initialState = {
    isIntro: true,
};

export default function IsIntro(state = initialState, action) {
    switch (action.type) {
        case 'SetIntro':
            return state = {isIntro: action.value};
        default:
            return state;
    }
}