const initialState = {
    filterprojects: 5
};

export default function FilterProjects(state = initialState, action) {
    switch (action.type) {
        case 'SetProjects':
            return state = {filterprojects: action.value};
        default:
            return state;
    }
}