const initialState = {
    scrollProgressvalue: [0, 0.26, 0.54, 0.85, 0.99, 1],
};

export default function ScrollProgressValue(state = initialState, action) {
    switch (action.type) {
        case 'SetScrollValue':
            return state = {scrollProgressvalue: action.value };
        default:
            return state;
    }
}