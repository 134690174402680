/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import "../../styles/main.scss"
import Intro from "../intro/intro";
import { ArrowScroll } from "../arrowscroll/arrowscroll";
import { useDispatch } from 'react-redux';
import LandScapeMode from "../mobile/landscape";

const Layout = ({ location, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [isMounted, setIsMounted] = useState(false);
  const [WindowScope, setWindowScope] = useState();
  const refContainer = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const documentGlobal = typeof document !== 'undefined' && document;
    const windowGlobal = typeof window !== 'undefined' && window;
    setIsMounted(true);
    setWindowScope(windowGlobal);
  }, [isMounted, WindowScope, refContainer]);

  let WindowScopeValue = WindowScope;

  return (
    <>
      <Intro />
      <ArrowScroll />
      <LandScapeMode />
      <TransitionGroup>
        <CSSTransition
          classNames='fade'
          timeout={500}
          in={isMounted}
          key={location.key}
        >
          <main className="w-screen" ref={refContainer}>
            {children}
          </main>
        </CSSTransition>
      </TransitionGroup>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default connect(state => ({
  isIntro: state.IsIntro.isIntro,
  project: state.ProjectViewer.project,
}), null)(Layout);
