const initialState = {
    Show: false,
    project: null,
};

export default function ProjectViewer(state = initialState, action) {
    switch (action.type) {
        case 'SetProject':
            return state = {Show: action.show, project:action.project};
        default:
            return state;
    }
}