const initialState = {
    filtertags: []
};

export default function FilterTags(state = initialState, action) {
    switch (action.type) {
        case 'SetTags':
            return state = {filtertags: action.value};
        default:
            return state;
    }
}