import React from 'react';
import Layout from './src/components/layout';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-180363091-1');

export { default as wrapRootElement } from './src/redux/ReduxWrapper';

//Call Google Analytics on route update
export const onRouteUpdate = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
};

//wrap redux provider to layout
export const wrapPageElement = ({ element, props }) => <Layout {...props}>{element}</Layout>

//intersection-observer polyfill
export const onClientEntry = async () => {
    if (typeof IntersectionObserver === `undefined`) {
      await import(`intersection-observer`);
    }
  }
 
  const getLatestSavedScrollPosition = (pathname) => {
    let n = sessionStorage.length;
    let i = 0;

    const partialKey = `@@scroll|${pathname}|`
    const initialkey = `@@scroll|${pathname}|initial`

    let results = [];

    while (++i < n) {
        const key = sessionStorage.key(i);
        
        if (key.includes(partialKey) && !key.includes(initialkey)) {
            results.push(key)
        }
    }

    if (results.length === 0) {
        return sessionStorage.getItem("@@scroll|/|initial");
    }

    results.sort();

    return sessionStorage.getItem(results[results.length - 1]);
}

  export const shouldUpdateScroll = ({
    routerProps: { location },
  }) => {
    const { pathname } = location

    if (pathname !== '/') {
      window.setTimeout(() => window.scrollTo(0, 0), 500);
      return false;
    } else if (pathname === '/') {
      const homePosition = getLatestSavedScrollPosition("/");
      console.log(homePosition);
      window.scrollTo(0, homePosition)
      return false;
    }

  };  
