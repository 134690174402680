import React, { useContext } from "react";
import { motion } from "framer-motion";
import { IntersectionObserver } from "../intersection/intersection";
import { IntersectionContext } from "../intersection/intersection";

export const StaggerLetter = ({ text }) => {

    const textArray = Array.from(text);
    const { inView } = useContext(IntersectionContext);

    const ParentAnim = {
        hidden: {
          opacity: 0,
          y: 0,
          duration: 1.5,
        },
        show: {
          opacity: 1,
          y: 0,
          transition: {
            type: 'spring',
            damping: 5,
            stiffness: 25,
            mass: 0.5,
            delayChildren:1.5,
            staggerChildren: 0.02,
          },
        },
        exit: {
          opacity: 1,
          y: 0,
          duration: 1.5,
        },
      };

    const ChildrenAnim = {
        hidden: {
            opacity: 0, y: 20,
        },
        show: {
            opacity: 1, y: 0,
            transition: {
                type: 'spring',
                damping: 20,
                stiffness: 200,
                duration: 0.2,
            }
        },
        exit: {
            opacity: 1,
            transition: {
                type: 'tween',
                duration: 0.5,
            }
        },
    }

    return(
        <>
            <IntersectionObserver isReset={true}>
                <motion.div
                    initial="initial"
                    animate={inView ? "show" : "hidden"}
                    exit="exit"
                    variants={ParentAnim}
                    className="flex flex-row justify-center"
                >
                    {textArray.map((letter, index) => (
                        <motion.div
                            key={index}
                            width={"auto"} // Set the width to the width of the letter
                            height={12} // Set the height to the height of the text
                            background={""}
                            style={{ position: "relative" }} // Position elements
                            variants={ChildrenAnim}
                            initial={{ opacity: 0, y: 20 }}
                        >
                            {letter === " " ? "\u00A0" : letter}
                        </motion.div>))}
                </motion.div>
            </IntersectionObserver>
        </>
    )
}