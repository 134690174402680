import React from "react"
import { motion, useTransform, useViewportScroll } from 'framer-motion';

export const ArrowScroll = () => {

    const { scrollYProgress } = useViewportScroll();
    const opacityMotionValue = useTransform(scrollYProgress, [0, 0.9, 1], [1, 1, 0]);

    return (
        <>
            <motion.div style={{ opacity: opacityMotionValue}} className="fixed flex items-center justify-center z-140 w-3 h-20 bottom-0 right-0 mr-5 md:mr-10 mb-10 pointer-events-none">
                <div className="arrow-line"></div>
                <div className="arrow-scroll"></div>
            </motion.div>
        </>
    )
}