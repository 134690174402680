import React, { useEffect, useState } from "react"
import { isMobileOnly, isTablet } from "react-device-detect";
import Lottie from 'react-lottie';
import * as animationLogoIntro from "../animations/lottie/coignet_anim_logo.json"

const LandScapeMode = () => {

  const [WindowScope, setWindowScope] = useState();
  const [isStopped, setIsStopped] = useState(false);

  const AnimLogoIntro = {
    loop: false,
    autoplay: false,
    animationData: animationLogoIntro.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  useEffect(() => {
    const windowGlobal = typeof window !== 'undefined' && window;
    setWindowScope(windowGlobal);
  }, [WindowScope]);

  return (
    <>
      {/* {isTablet &&
        <div className="portrait-mode-tablet flex-col text-white fixed z-170 top-0 left-0 h-screen w-screen background-blue-edmond flex items-center justify-center">
          <div className="flex flex-col w-10/12 items-center">
            <div className="w-1/2 pointer-events-none">
              <Lottie options={AnimLogoIntro}
                height="auto"
                href={WindowScope !== undefined && WindowScope.location.href}
                width="100%"
                speed={0.8}
                isStopped={isStopped}
                isPaused={isStopped}
              />
            </div>
            <div className="w-1/2 mb-5 sofia-semibold uppercase text-center text-white">Pour une meilleur expérience visualiser en mode paysage.</div>
          </div>
        </div>
      } */}
      {isMobileOnly &&
        <div className="portrait-mode flex-row text-white fixed z-170 top-0 left-0 h-screen w-screen background-blue-edmond flex items-center justify-center">
          <div className="flex flex-row w-10/12 items-center">
            <div className="w-1/2 pointer-events-none">
              <Lottie options={AnimLogoIntro}
                height="auto"
                href={WindowScope !== undefined && WindowScope.location.href}
                width="100%"
                speed={0.8}
                isStopped={isStopped}
                isPaused={isStopped}
              />
            </div>
            <div className="w-1/2 mb-5 sofia-semibold uppercase text-center text-white">Ce site ne supporte pas le mode paysage.</div>
          </div>
        </div>
      }
    </>
  )
}

export default LandScapeMode