const initialState = {
    sectionVisible: "home",
};

export default function SectionVisible(state = initialState, action) {
    switch (action.type) {
        case 'SetSectionVisible':
            return state = {sectionVisible: action.value };
        default:
            return state;
    }
}