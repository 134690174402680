import React, { useEffect, useState } from "react";
import * as animationLogoIntro from "../animations/lottie/coignet_anim_logo.json"
import Lottie from 'react-lottie';
import { useDispatch } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import { connect } from 'react-redux';
import { StaggerLetter } from "../animations/StaggerLetter";
import {
    isBrowser,
    isMobileOnly
  } from "react-device-detect";

const Intro = ({ isIntro }) => {

    const [WindowScope, setWindowScope] = useState();
    const [isStopped, setIsStopped] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        const windowGlobal = typeof window !== 'undefined' && window;
        setWindowScope(windowGlobal);
        windowGlobal.setTimeout(() => setIsStopped(false), 700);
    }, [WindowScope]);

    let WindowScopeValue = WindowScope;


    const AnimLogoIntro = {
        loop: false,
        autoplay: false,
        animationData: animationLogoIntro.default,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    function endAnim() {
        dispatch({ type: 'SetIntro', value: false });
    }

    const IntroTransition = {
        initial: {
            opacity: 0,
        },
        enter: {
            opacity: 1,
            transition: {
                duration: 0.35,
                delay: 0.35,
            },
        },
        exit: {
            opacity: 0,
            transition: {
                delay: 0.2,
                duration: 0.35,
            },
        },
    };

    const BgTransition = {
        initial: {
            scaleX: 1,
            originX: 1
        },
        enter: {
            scaleX: 1,
            originX: 1,
            },
            exit: {
                scaleX: 0,
                originX: 1,
                transition: {
                    type: 'spring',
                    damping: 15,
                    stiffness: 60,
                    duration: 0.3,
                },
            },
        };

        return(
        <>
            <AnimatePresence>
            {isIntro &&
                    <div className="w-screen pointer-events-none h-screen fixed flex flex-col items-center justify-center z-160">
                    <motion.div
                        key="Intro"
                        variants={IntroTransition}
                        initial="initial"
                        animate="enter"
                        exit="exit"
                        id="Intro"
                        className="pointer-events-auto w-screen h-screen fixed flex flex-col items-center justify-center z-160">
                        <div className="w-full md:w-1/2 pointer-events-none">
                            <Lottie options={AnimLogoIntro}
                                height="auto"
                                href={WindowScopeValue !== undefined && WindowScopeValue.location.href}
                                width="100%"
                                speed={0.8}
                                isStopped={isStopped}
                                isPaused={isStopped}
                                eventListeners={[{ eventName: 'complete', callback: () => endAnim() }]}
                            />
                        </div>
                        <div className="text-base md:text-2xl xxl:text-3xl z-10 overflow-hidden sofia-bold uppercase text-white tracking-widest2">
                            {!isMobileOnly && <StaggerLetter text="la confiance, notre plus bel actif" />}
                            {isMobileOnly && 
                            <>
                            <StaggerLetter text="la confiance," />
                            <StaggerLetter text="notre plus bel actif" />
                            </>
                            }
                        </div>
                    </motion.div>
                    </div>
                }
                </AnimatePresence>
                <AnimatePresence>
                {isIntro &&
                    <motion.div
                        key="IntroBg"
                        variants={BgTransition}
                        initial="initial"
                        animate="enter"
                        exit="exit"
                        id="Intro"
                        className="w-screen h-screen fixed flex flex-col items-center justify-center z-150 background-blue-edmond">
                    </motion.div>
                }
            </AnimatePresence>
        </>
    )
}


export default connect(state => ({
    isIntro: state.IsIntro.isIntro,
}), null)(Intro);