/* eslint-disable import/prefer-default-export */
/* eslint-disable arrow-parens */
/* eslint-disable eol-last */
/* eslint-disable import/prefer-default-export */
import { combineReducers } from 'redux';

//reducer 
import PosScroll from './reducer/posScroll';
import IsIntro from './reducer/isIntro';
import ProjectViewer from './reducer/projectviewer';
import FilterTags from './reducer/tags';
import FilterProjects from './reducer/projects';
import ScrollProgressValue from './reducer/scrollhomevalue';
import SectionVisible from './reducer/sectionvisible';

const rootReducer = combineReducers({ PosScroll, IsIntro, ProjectViewer, FilterTags, FilterProjects, ScrollProgressValue, SectionVisible});

// preloadedState will be passed in by the plugin
export default rootReducer;
