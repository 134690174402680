module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1000},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Edmond Coignet","short_name":"Edmond Coignet","start_url":"/","background_color":"#cfdee1","theme_color":"#5B6B74","display":"standalone","icon":"src/favicon/icon.png","icons":[{"src":"/favicons/icon-48x48.png","sizes":"48x48","type":"image/png","purpose":"maskable"},{"src":"/favicons/icon-72x72.png","sizes":"72x72","type":"image/png","purpose":"maskable"},{"src":"/favicons/icon-96x96.png","sizes":"96x96","type":"image/png","purpose":"maskable"},{"src":"/favicons/icon-144x144.png","sizes":"144x144","type":"image/png","purpose":"maskable"},{"src":"/favicons/icon-192x192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"/favicons/icon-384x384.png","sizes":"384x384","type":"image/png","purpose":"maskable"},{"src":"/favicons/icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"723aedd1bdbf1950b3ee05dc62c9908f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#5B6B74","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
